import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Schedule from "../components/schedule";

const SydneyPage = () => (
    <Layout>
        <SEO title="KOFFIA Sydney Schedule" />
        <Schedule city="Sydney" />
    </Layout>
);

export default SydneyPage;
